import $ from 'jquery'

const $doc = $(document)

function addDataLabelsToTable() {

  const $tables = $('.wysiwyg-editor table')
  
  if ($tables.length) {
    $tables.each((i, e) => {
      let tableHeader = []
      let $firstrow
      const hasMoreThanOneRow = $(e).find('tr').length > 1
      hasMoreThanOneRow && $(e).addClass('table-chart')

      if (!$(e).find('th').length) {
        $firstrow = $($(e).find('tr')[0])
        $firstrow.find('td').each((i, e) => {
          tableHeader[i] = e.innerText
        })
      } else {
        $firstrow = $($(e).find('th')[0])
        $firstrow.each((i, e) => {
          tableHeader[i] = e.innerText
        })
      }

      (hasMoreThanOneRow && $firstrow) && $firstrow.addClass('table-chart__header')

      $(e).find('tr:gt(0)').each((i, e) => {
        const $tr = $(e)
        
        $tr.find('td').each((i, e) => {
          e.dataset.label = tableHeader[i]
        })
      })

    })
  }

}

export default function initWysiwygFunctions(){
  addDataLabelsToTable();
}
