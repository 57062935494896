import $ from 'jquery'

const backToTopButton = $('.js-button-top')

export default function backToTop() {
  backToTopButton.on('click', function(e) {
    e.preventDefault();
    $('html, body').animate({scrollTop:0}, '300');
  });
}
