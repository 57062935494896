import $ from 'jquery'
const $doc = $(document)
const content = $('.indigenous__content')
const statement = $('.indigenous__extended')

function openIndigenousStatement() {
  if (statement) {
    statement.show();
    content.hide();
  }
}

function closeIndigenousStatement() {
  if (statement) {
    statement.hide();
    content.show();
  }
}

export default function initIndigneousText(){
  $doc.on('click', '.js-open-indigenous', openIndigenousStatement )
  $doc.on('click', '.js-close-indigenous', closeIndigenousStatement )
}
